import React, { useState } from "react";
import LeadForm from "./components/LeadForm";
import "./assets/scss/style.scss";
import Logo from "../src/assets/images/img001.svg";
import myGardenLogo from "../src/assets/images/myGardenLogo.svg";
import successGif from "../src/assets/images/successGif.gif";
import "add-to-calendar-button";
function App() {
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const [isFormFailed, setIsFormFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  return (
    <div className="wrapper">
      <div className="container">
        <div className="launchDetails">
          <div className="logo">
            <img src={Logo} alt="danube home" />
          </div>
          {/* <div className="invitation">You are cordially invited to the</div>
          <div className="logo">
            <img src={Logo} alt="danube home" />
          </div>
          <div className="grandRelaunch">
            GRAND RELAUNCH
            <span class="grandRelaunchShadow">GRAND RELAUNCH</span>
          </div>
          <div className="ceremony">Ceremony of our showroom in</div>
          <div className="launchDestination">RAS AL KHAIMAH</div>
          <div className="launchDate">
            <span>October</span>
            <span>4,</span>
            <span>2023 -</span>
            <span>11 AM Onwards</span>
          </div> */}
        </div>

        {!isFormSuccess && (
          <LeadForm
            setIsFormSuccess={setIsFormSuccess}
            setIsFormFailed={setIsFormFailed}
            setErrorMessage={setErrorMessage}
          />
        )}
        {isFormSuccess && (
          <div className="launchRegisterFormSubmission">
            <img src={successGif} alt="danube home" width="130" />
            <h2>Thank You</h2>
            <p>
              Thank you for reserving your spot! Our team will share you further
              updates.
            </p>
            <div className="calendar">
              <add-to-calendar-button
                name="Danube Home Stylish FTV Collection"
                description="FASHION FINDS ITS HOME"
                startDate="2023-11-07"
                startTime="18:00"
                endDate="2023-11-07"
                endTime="20:00"
                timeZone="Asia/Dubai"
                location="Danube Home -Al Barsha - Level1"
                organizer="Danube Home|pr@aldanube.com"
                availability="free"
                options="'Apple','Google','Outlook.com','Microsoft365'"
                buttonStyle="default"
                size="7"
              ></add-to-calendar-button>
            </div>
          </div>
        )}
        {isFormFailed && (
          <>
            <div className="errorPopup">
              <span>{errorMessage}</span>
            </div>
          </>
        )}

        <div className="launchRSVP">
          <span>RSVP</span>
          <p>Aditi Khare </p>
          <a href="mailto:pr@aldanube.com">pr@aldanube.com</a>
          <a href="tel:+971506276465">Mob: +971 50 627 6465</a>
        </div>
      </div>
    </div>
  );
}
export default App;
