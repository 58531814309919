// src/components/UserForm.js

import React, { useEffect, useState } from "react";
import loadingCircle from "../../src/assets/images/loadingCircle1.svg";
const LeadForm = ({ setIsFormSuccess, setIsFormFailed, setErrorMessage }) => {
  const [formData, setFormData] = useState({});
  const [mediaType, setMediaType] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onOptionChange = (e) => {
    setMediaType(e);
  };
  useEffect(() => {
    setFormData({ ...formData, mediaType });
  }, [mediaType]);

  const resetIsFormFailed = () => {
    setTimeout(() => {
      setIsFormFailed(false);
    }, 2000); // 2000 milliseconds = 2 seconds
  };

  const handleSubmit = (e) => {
    setIsLoader(true);
    e.preventDefault();
    fetch("https://dnd.danubehome.com/api/v1/rsvp/insert", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJEYW51YmUgSG9tZSBKV1QiLCJhdWQiOiJEYW51YmUgSG9tZSBKV1QiLCJzdWIiOiJEYW51YmUgSG9tZSBKV1QiLCJpYXQiOjE2ODM3MDI5NzF9.L8MNoE_g8Rb1zE25HaR0vgiEvnFL6RLvLrU62Z0-PZg",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        setIsLoader(false);
        if (data.resdata.response == "success") {
          // console.log("Form submission successful:", data);
          setIsFormSuccess(true);
          setIsFormFailed(false);
          // Reset the form
          setFormData({});
        } else {
          setErrorMessage(data.resdata.message);
          setIsFormFailed(true);
          resetIsFormFailed();
        }
      })
      .catch((error) => {
        console.error("Form submission error:", error);
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="launchRegisterForm">
          <div className="title">Non-Transferable Invite</div>
          <div className="firstName">
            <label htmlFor="fname">First Name</label>
            <input
              type="text"
              id="fname"
              name="fname"
              value={formData.fname}
              onChange={handleInputChange}
              required
              className="inputBox"
            />
          </div>

          <div className="lastName">
            <label htmlFor="lname">Last Name</label>
            <input
              type="text"
              id="lname"
              name="lname"
              value={formData.lname}
              onChange={handleInputChange}
              required
              className="inputBox"
            />
          </div>

          <div className="email">
            <label htmlFor="email">Email Id</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              required
              className="inputBox"
            />
          </div>
          <div className="mobile">
            <label htmlFor="mobile">Mobile</label>
            <input
              type="mobile"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleInputChange}
              required
              className="inputBox"
            />
          </div>

          <div className="mediaType">
            Media Type
            {/* <div className="radio">
              <label>
                <input
                  type="radio"
                  value="Media"
                  onChange={handleInputChange}
                />
                Media
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="Influencer"
                  onChange={handleInputChange}
                />
                Influencer
              </label>
            </div> */}
            <div className="mediaTypeSection">
              <div className="radio">
                <input
                  id="Media"
                  name="mediaType"
                  type="radio"
                  value="Media"
                  checked={mediaType === "Media"}
                  onChange={() => onOptionChange("Media")}
                  required
                />
                <label htmlFor="Media" className="radio-label">
                  Media
                </label>
              </div>
              <div className="radio">
                <input
                  id="Influencer"
                  name="mediaType"
                  type="radio"
                  value="Influencer"
                  checked={mediaType === "Influencer"}
                  onChange={() => onOptionChange("Influencer")}
                  required
                />
                <label htmlFor="Influencer" className="radio-label">
                  Influencer
                </label>
              </div>
            </div>
          </div>
          <div className="socialCreds">
            <label htmlFor="InfluencerMedia">Influencer/Media Name</label>
            <input
              type="text"
              id="InfluencerMedia"
              name="mediaInfluencerName"
              value={formData.mediaInfluencerName}
              onChange={handleInputChange}
              required
              className="inputBox"
            />
          </div>
          <div className="action">
            <button type="submit">
              <span>Submit </span>
              {isLoader ? (
                <>
                  <div className="Loading">
                    <img src={loadingCircle} alt="danube home" height="28" />
                  </div>
                </>
              ) : null}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LeadForm;
